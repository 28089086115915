#navbar {
  background-color: rgba(255, 255, 255, 0.89);
  margin-bottom: 0;
  font-weight: bold;
  min-height: 65px;
  flex: 0 0 auto;
  z-index: 999;
  font-size: 16px;
  font-family: 'Nunito';
  .container-fluid {
    padding: 0 30px;
  }

  .navbar-header {
    display: flex;
    align-items: center;

    > a {
      margin-right: auto;
    }

    .logo {
      margin: 15px 0;
      height: 35px;
    }
  }

  .toggle .icon-bar {
    background-color: #313332;
  }

  .nav {
    > li {
      > a {
        margin: 15px 10px;
        padding: 0;
        color: #313332;
        display: block;
        line-height: 35px;
        background-color: transparent;

        &:hover {
          color: #777;
        }
        > svg {
          margin-bottom: 4px;
        }
      }
    }
  }

  .dropdown-menu {
    border: 1px solid #ccc;
    border-radius: 4px;
    right: 10px;
    top: 85%;

    > li a {
      display: block;
      color: #313332;
      width: 100%;
      height: 100%;
      padding: 3px 15px;
      background-color: transparent;

      > svg {
        margin-bottom: 3px;
      }
    }

    > li:hover a {
      background-color: #29c086;
      color: white;
    }
  }

  .user-image {
    margin-right: 5px;
    margin-bottom: 3px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
  }

  a:hover,
  a:focus {
    background-color: transparent;
  }
}
